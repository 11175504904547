const table = {
  data() {
    return {
      columns: [
        {
          prop: 'reason',
          label: '停机名称'
        },
        {
          width: 230,
          prop: 'description',
          label: '描述'
        }
      ]
    };
  },
  methods: {}
};
export { table };
