<template>
  <div class="bugconfig">
    <el-button class="iconfont icon-dctianjia" @click="addFirst" style="margin-bottom: 20px">添加</el-button>
    <Tree-table @add="add" @edit="edit" @del="del" :columns="columns" :data="treeData" />
    <Pagination class="pagination fr" @fetch="changePage" v-bind="pageoption" />
  </div>
</template>

<script>
import TreeTable from './treeTable.vue';
import Pagination from '@/components/pagination/pagination';
import { table } from './mixin.js';
export default {
  data() {
    return {
      treeData: [],
      pageoption: {
        total: 0,
        pageSize: 20,
        currentPage: 1
      }
    };
  },
  mixins: [table],
  components: { TreeTable, Pagination },
  mounted() {
    this.init();
  },
  methods: {
    async init(param = {}) {
      let data = {
        currentPage: param.currentPage || this.pageoption.currentPage,
        currentPageSize: param.currentPageSize || this.pageoption.pageSize
      };
      const res = await this.$apis.bugconfig.get(data);
      const { items, total, currentPage, currentPageSize } = res;
      this.treeData = items;
      this.pageoption.total = total;
      this.pageoption.currentPage = currentPage;
      this.pageoption.pageSize = currentPageSize;
      // css加载延迟
      this.$nextTick(() => {
        document.querySelectorAll('.el-table__row').forEach(item => {
          item.childNodes[0].childNodes[0].style.display = 'flex';
          item.childNodes[0].childNodes[0].style.alignItems = 'center';
        });
      });
    },
    addFirst() {
      this.$router.push({ path: `/bugconfig/add` });
    },
    add(item) {
      this.$router.push({ path: `/bugconfig/add/${item.id}` });
    },
    edit(item) {
      this.$router.push({ path: `/bugconfig/edit/${item.id}/${item.level}` });
    },
    del(item) {
      this.$con({ title: '系统提示', detail: '您确认要删除该停机类型吗?', Deldisable: true }).then(async () => {
        const { fail } = await this.$apis.bugconfig.del({ id: item.id });
        if (!fail) {
          this.init();
        }
      });
    },
    changePage(item) {
      this.init(item);
    }
  }
};
</script>
<style scoped>
/* tbody:nth-child(0) */
</style>
