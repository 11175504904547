<template>
  <div class="tree-table">
    <el-table :data="data" v-loading="loading" :height="height" row-key="id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column :prop="column.prop" :label="column.label" :width="700" v-for="column in scopeColumns" :key="column.prop" :align="column.align || 'left'">
        <template slot-scope="scope">
          <div class="ellipsis">{{ scope.row[column.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="right">
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot="header" slot-scope="scope">
          <span class="el-dropdown-link">操作</span>
          <el-dropdown trigger="click">
            <i class="iconfont icon-dcshaixuan1"></i>
            <el-dropdown-menu slot="dropdown">
              <el-checkbox-group v-model="checkColumns">
                <el-checkbox v-for="column in columns" :key="column.prop" :label="column.label"></el-checkbox>
              </el-checkbox-group>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">
          <div>
            <el-button type="text" size="small" @click="$emit('add', scope.row)" v-if="scope.row.level < 3">
              <el-tooltip content="添加" placement="top">
                <i :class="TABLE.icons.add" class="iconfont"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="$emit('edit', scope.row)">
              <el-tooltip content="编辑" placement="top">
                <i :class="TABLE.icons.edit" class="iconfont"></i>
              </el-tooltip>
            </el-button>
            <el-button v-if="scope.row.endNode" type="text" size="small" @click="$emit('del', scope.row)">
              <el-tooltip content="删除" placement="top">
                <i :class="TABLE.icons.del" class="iconfont"></i>
              </el-tooltip>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { SIMULATE_NETWORK_TIME, truthy, falsy, TABLE } from '/app.config';
export default {
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    // 表格的 columns
    columns: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      TABLE,
      truthy,
      falsy,
      height: 0,
      loading: false,
      scopeColumns: this.columns,
      checkColumns: this.columns.map(column => column.label)
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (document.querySelector('.list-container > .main')) {
        this.height = document.querySelector('.list-container > .main').clientHeight;
      } else {
        this.height = '700px';
      }
      // this.height = document.querySelector('.list-container > .main').clientHeight;
    });
  },
  watch: {
    checkColumns(val) {
      this.loading = true;
      setTimeout(() => {
        this.scopeColumns = this.columns.filter(cloumn => {
          return val.includes(cloumn.label);
        });
        this.loading = false;
      }, SIMULATE_NETWORK_TIME);
    }
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import '@/components/table/table.scss';
@import '@/common/style/var';
.tree-table {
  height: 100%;
  .icon {
    font-size: 16px;
    color: $--color-primary;
  }
  /deep/ .el-table__row {
    .el-table_1_column_1 {
      .cell {
        display: flex;
        align-items: center;
      }
    }
  }
  .el-dropdown-menu__item {
    text-align: center;
  }
}
</style>
